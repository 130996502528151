<template>
  <AppDialog v-model="model">
    <template #header>
      <AppDialogHeader>{{ agentDetails.name }}</AppDialogHeader>
    </template>

    <AppSpinnerBig :loading="loading" inline />
    <div v-if="!loading">
      <p class="mb-4">
        {{ agentAddress }}
      </p>
      <p v-if="agentDetails.phone">
        <span class="type-subtitle font-bold">
          {{ $t('ComponentAgentDetailsDialog.PhoneText').value }}
        </span>
        <span>
          <a :href="`tel:${agentDetails.phone}`">&nbsp;{{ agentDetails.phone }}</a>
        </span>
      </p>
    </div>

    <template #footer>
      <AppDialogFooter>
        <AppButton type="button" analytics-name="agent-details-dialog-close" @click="model = false">
          {{ $t('ComponentAgentDetailsDialog.ButtonClose').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useI18nStore } from '@galileo/stores'

import {
  useVModel,
  AppDialog,
  AppDialogFooter,
  AppSpinnerBig,
  AppButton,
  AppDialogHeader,
} from '@oen.web.vue2/ui'

export default {
  name: 'AgentDetailsDialog',
  emits: ['input'],
  components: {
    AppDialogHeader,
    AppDialog,
    AppDialogFooter,
    AppSpinnerBig,
    AppButton,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    agentDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const agentAddress = computed(() => {
      const fields = [props.agentDetails.street, props.agentDetails.city, props.agentDetails.state]
      return fields.filter(Boolean).join(', ')
    })
    return {
      agentAddress,
      model,
      $t,
    }
  },
}
</script>
