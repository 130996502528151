<template>
  <SendMoneyFeeListItem
    v-if="promoBonus"
    :title="title"
    :loading="loading"
    :fee="`+${promoBonus}`"
    :currency="currencyFrom"
    class="promo"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { title, loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'

import { useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyPromoBonus',
  emits: [],
  components: {
    SendMoneyFeeListItem,
  },
  props: {
    title,
    loading,
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const promoBonus = computed(() => sendMoneyStore.getPromoBonusAmount(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    return {
      promoBonus,
      currencyFrom,
    }
  },
}
</script>

<style scoped>
.promo {
  @apply text-green-text;
  @apply type-subtitle;
  @apply font-bold;

  ::v-deep .description-list-item-definition {
    @apply text-green-text font-bold;
  }
}
</style>
