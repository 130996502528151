<template>
  <LoadingTransition :tag="tag" :loading="loading"> {{ preText }} {{ rateTo }} </LoadingTransition>
</template>

<script>
import { loading, tag } from './-common-props'
import LoadingTransition from '@galileo/components/LoadingTransition/LoadingTransition'
export const props = {
  tag,
  loading,
  rateFrom: {
    type: [String, Number],
  },
  currencyFrom: {
    type: String,
    default: '',
  },
  rateTo: {
    type: [String, Number],
  },
  currencyTo: {
    type: String,
    default: '',
  },
  lastUpdate: {
    type: [String, Date],
    default: null,
  },
  preText: {
    type: String,
    default: '',
  },
}
export default {
  name: 'SendMoneyRateAtom',
  emits: [],
  components: {
    LoadingTransition,
  },
  props,
}
</script>

<style scoped></style>
