<template>
  <SendMoneyFeeListItem
    @infoDialog="$emit('infoDialog')"
    :title="title"
    :loading="loading"
    :fee="fees"
    :currency="currencyFrom"
    :showInfo="showInfo"
  >
    <template v-if="$slots.content" #content>
      <slot name="content" />
    </template>
  </SendMoneyFeeListItem>
</template>

<script>
import { computed } from '@vue/composition-api'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { title, loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'

import { useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyTransferFees',
  emits: [],
  components: {
    SendMoneyFeeListItem,
  },
  props: {
    title,
    loading,
    showInfo: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const fees = computed(() => sendMoneyStore.getCalculatedFee(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    return {
      fees,
      currencyFrom,
    }
  },
}
</script>

<style scoped></style>
