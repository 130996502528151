<template>
  <SendMoneyRate
    :title="$t('PageSendMoneySummary.ExchangeRateText').value"
    :pre-text="exchangedCurrency"
    :loading="loading"
    :show-info="false"
  />
</template>

<script>
import { computed } from '@vue/composition-api'

import { loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import SendMoneyRate from '@galileo/components/Views/SendMoney/Organisms/SendMoneyRate'
import { SendMoneyRateListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { SendMoneyRateAtom } from '@galileo/components/Views/SendMoney/Atoms'


import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneySummaryRates',
  emits: [],
  components: {
    SendMoneyRate,
    SendMoneyRateListItem,
    SendMoneyRateAtom,
  },
  props: {
    loading,
  },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const rateFrom = computed(() => sendMoneyStore.getRateFrom(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    const exchangedCurrency = computed(() => {
      return 1 + ' ' + currencyFrom.value + ' = '
    })

    return {
      $t,
      rateFrom,
      currencyFrom,
      exchangedCurrency,
    }
  },
}
</script>

<style scoped></style>
