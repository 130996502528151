<template>
  <SendMoneyFeeListItem
    :title="
      $t('SendMoneySummary.RecipientGets', {
        recipientName: truncateRecipientName(recipientName, 20),
      }).value
    "
    :loading="loading"
    :analytics-name="recipientName"
  >
    <template #content>
      <div class="flex">
        <!-- <img
          v-if="rateChanged"
          class="mr-1"
          :alt="$t('PageSendMoneySummary.RateChangedImgAlt').value"
          :src="require('@galileo/assets/images/misc/dot-yellow.svg')"
        /> -->
        <template v-if="splitCurrencies">
          <SendMoneyFeeAtom
            v-for="(split, currency) in splitCurrencies"
            :key="currency"
            tag="div"
            :loading="loading"
            :fee="split.amountTo"
            :currency="currency"
            :data-testid="testingId"
          />
        </template>
        <template v-else>
          <SendMoneyFeeAtom
            :loading="loading"
            :fee="amountTo"
            :currency="currencyTo"
            :data-testid="testingId"
          />
        </template>
      </div>
    </template>
  </SendMoneyFeeListItem>
</template>

<script>
import { computed } from '@vue/composition-api'

import { loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import { SendMoneyFeeAtom } from '@galileo/components/Views/SendMoney/Atoms'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneySummaryRecipientGets',
  emits: [],
  components: {
    SendMoneyFeeListItem,
    SendMoneyFeeAtom,
  },
  props: {
    loading,
  },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const recipientName = computed(() => sendMoneyStore.getRecipientName)

    // Rate changed
    const rateChanged = computed(
      () => sendMoneyStore.getRate() !== sendMoneyStore.getConfirmedRate(false)
    )

    // Amount to details
    const amountTo = computed(() => sendMoneyStore.getAmountTo(true))
    const currencyTo = computed(() => sendMoneyStore.form.currencyTo)

    // Split currencies
    const splitCurrencies = computed(() => sendMoneyStore.getSplitCurrencies(true))

    const truncateRecipientName = (str, num) => {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }

    const testingId = 'send-money-summary-amount-to'

    return {
      $t,
      rateChanged,
      splitCurrencies,
      recipientName,
      amountTo,
      currencyTo,
      truncateRecipientName,
      testingId,
    }
  },
}
</script>

<style scoped>
.rate-changed {
  @apply text-yellow-text;

  ::v-deep .description-list-item-definition {
    @apply text-yellow-text;
  }
}
</style>
