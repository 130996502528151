<template>
  <SendMoneyRateListItem
    v-if="rate"
    :title="title"
    :pre-text="preText"
    :loading="loading"
    :rate-from="rateFrom"
    :currency-from="currencyFrom"
    :rate-to="rate"
    :currency-to="currencyTo"
    :last-update="lastUpdate"
    :show-info="showInfo"
    @infoDialog="$emit('infoDialog', $event)"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { loading, title } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import { SendMoneyRateListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyRate',
  emits: ['infoDialog'],
  components: {
    SendMoneyRateListItem,
  },
  props: {
    loading,
    title,
    showInfo: {
      type: Boolean,
      default: true,
    },
    preText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const rateFrom = computed(() => sendMoneyStore.getRateFrom(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)
    const rate = computed(() => sendMoneyStore.getRate(true))
    const currencyTo = computed(() => sendMoneyStore.form.currencyTo)
    const lastUpdate = computed(() => sendMoneyStore.form.lastUpdate)

    return {
      rate,
      rateFrom,
      currencyFrom,
      currencyTo,
      lastUpdate,
    }
  },
}
</script>

<style></style>
