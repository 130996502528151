<template>
  <AppDescriptionListItem>
    <template #title>{{ $t('PageSendMoneySummary.SendingText').value }}</template>
    <template #content>
      <SendMoneyFeeAtom
        tag="div"
        :loading="loading"
        :fee="amountFrom"
        :currency="currencyFrom"
        data-testid="send-money-summary-amount-from"
      />
      <!-- <div>
        <AppLink
          class="type-caption font-bold"
          analytics-name="send-summary-edit-amount"
          @click="$emit('edit')"
        >
          {{ $t('PageSendMoneySummary.ButtonEditAmount').value }}
          <template #rightIcon>
            <AppIcon :size="16" :name="$t('PageSendMoneySummary.ButtonEditAmount').value">
              <IconEdit />
            </AppIcon>
          </template>
        </AppLink>
      </div> -->
    </template>
  </AppDescriptionListItem>
</template>

<script>
import { computed } from '@vue/composition-api'

import { loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import { SendMoneyFeeAtom } from '@galileo/components/Views/SendMoney/Atoms'
import { IconEdit } from '@oen.web.vue2/icons'

import { AppLink, AppIcon, AppDescriptionListItem } from '@oen.web.vue2/ui'


import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneySummarySendingAmount',
  emits: ['edit'],
  components: {
    AppDescriptionListItem,
    SendMoneyFeeAtom,
    AppLink,
    AppIcon,
    IconEdit,
  },
  props: {
    loading,
  },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const amountFrom = computed(() => sendMoneyStore.getAmountFrom(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    return {
      $t,
      amountFrom,
      currencyFrom,
    }
  },
}
</script>

<style scoped></style>
