<template>
  <LoadingTransition :tag="tag" :loading="loading">{{ fee }} {{ currency }}</LoadingTransition>
</template>

<script>
import { loading, tag } from './-common-props'
import LoadingTransition from '@galileo/components/LoadingTransition/LoadingTransition'
export const props = {
  tag,
  loading,
  fee: {
    type: [String, Number],
  },
  currency: {
    type: String,
    default: '',
  },
}
export default {
  name: 'SendMoneyFeeAtom',
  emits: [],
  components: {
    LoadingTransition,
  },
  props,
}
</script>

<style scoped></style>
