<template>
  <div class="recipient-gets-explanation">
    {{ $t('PageSendMoneySummary.RecipientGetsExplanationText').value }}
    <div v-if="splitCurrencies" class="pt-6">
      {{ $t('PageSendMoneySummary.RecipientGetsEstExplanationText').value }}
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'



import { useI18nStore,  useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneySummaryRecipientGetExplanation',
  emits: [],
  components: {},
  props: {},
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    // Split currencies
    const splitCurrencies = computed(() => sendMoneyStore.getSplitCurrencies(true))

    return {
      $t,
      splitCurrencies,
    }
  },
}
</script>

<style scoped>
.recipient-gets-explanation {
  @apply type-caption mb-6 text-secondary-text;
}
</style>
