<template>
  <SendMoneyFeeListItem
    :title="title"
    :loading="loading"
    :fee="totalAmount"
    :currency="currencyFrom"
    :analytics-name="analyticsName"
    class="font-bold text-primary-text total-row"
  >
    <template v-if="$slots.content" #content>
      <slot name="content" />
    </template>
  </SendMoneyFeeListItem>
</template>

<script>
import { computed } from '@vue/composition-api'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { title, loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'


import { useSendMoneyStore, useEnvStore } from '@galileo/stores'

export default {
  name: 'SendMoneyTransferTotal',
  emits: [],
  components: {
    SendMoneyFeeListItem,
  },
  props: {
    title,
    loading,
    analyticsName: {
      type: String,
      default: '',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const totalAmount = computed(() => sendMoneyStore.getTotalAmount(true))
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    return {
      totalAmount,
      currencyFrom,
    }
  },
}
</script>

<style scoped>
::v-deep .description-list-item-term-title,
::v-deep .description-list-item-definition {
  @apply text-base !important;
}
</style>
