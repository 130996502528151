<template>
  <Component :is="tag" :class="transitionClasses" v-bind="$attrs" v-on="$listeners">
    <slot />
  </Component>
</template>

<script>
import { computed } from '@vue/composition-api'

import { useTransition } from '@oen.web.vue2/ui'

export const transition = ['fade', 'fade-half', 'icon-expand', 'blur']

export default {
  name: 'LoadingTransition',
  props: {
    tag: {
      type: String,
      default: 'span',
      required: false,
    },
    transition: {
      type: String,
      default: 'fade-half',
      required: false,
      validator: (val) => transition.includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    const isLoading = computed(() => props.loading)
    const transition = computed(() => props.transition)

    const transitionClasses = computed(() => {
      return useTransition(transition.value, isLoading).value
    })
    return {
      transitionClasses,
    }
  },
}
</script>

<style scoped></style>
