var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recipient-gets-explanation"},[(_vm.countryTo === 'LB' && _vm.deliveryMethod === 'CashPayout' && _vm.currencyTo === 'USD')?_c('div',{staticClass:"terms"},[_c('p',{staticClass:"terms-heading"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneySummary.LebanonTransferText').value)+" ")])]):(_vm.customerRegion === 'US')?_c('div',{staticClass:"terms"},[_c('p',{staticClass:"terms-heading"},[_vm._v(" "+_vm._s(_vm.$t('PageSendMoneySummary.BanksText').value)+" ")])]):_vm._e(),_c('div',{staticClass:"terms"},[_c('div',{staticClass:"terms-heading",domProps:{"innerHTML":_vm._s(
        _vm.$t('PageSendMoneySummary.TermsAndConditionsText', { termsLink: _vm.termsLink, privacyPolicyLink: _vm.privacyPolicyLink }).value
      )}})]),(_vm.paymentMethod === 'DirectDebit' && (_vm.customerRegion === 'CA' || _vm.customerRegion === 'US'))?_c('div',{staticClass:"terms"},[_c('div',{staticClass:"terms-heading",domProps:{"innerHTML":_vm._s(_vm.$t('PageSendMoneySummary.PreAuthAgreementtext', { preAuthAgreementLink: _vm.preAuthAgreementLink }).value)}})]):_vm._e(),(_vm.customerRegion === 'AU')?_c('div',{staticClass:"terms"},[_c('div',{staticClass:"terms-heading",domProps:{"innerHTML":_vm._s(
        _vm.$t('ComponentSendMoneySummaryDisclaimer.DisclosureStatementsTextAU', {
          productDisclosureLink: _vm.productDisclosureLink,
          financialServicesGuideLink: _vm.financialServicesGuideLink,
        }).value
      )}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }