<template>
  <AppDescriptionListItem>
    <template #title>
      <div class="fee-title">
        {{ title }}
        <AppButton v-if="showInfo" theme="icon" @click="$emit('infoDialog')">
          <AppIcon name=" " class="appIcon">
            <IconInformation />
          </AppIcon>
        </AppButton>
      </div>
    </template>
    <template #content>
      <Transition name="fade" mode="out-in">
        <slot name="content">
          <SendMoneyFeeAtom
            class="fee"
            key="value"
            :tag="tag"
            :loading="loading"
            :fee="fee"
            :currency="currency"
            data-testid="send-money-fees"
          />
        </slot>
      </Transition>
    </template>
  </AppDescriptionListItem>
</template>

<script>
import { title } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import SendMoneyFeeAtom, { props } from '@galileo/components/Views/SendMoney/Atoms/SendMoneyFeeAtom'
import { IconInformation } from '@oen.web.vue2/icons'



import { AppIcon, AppDescriptionListItem, AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'SendMoneyFeeListItem',
  components: {
    AppDescriptionListItem,
    SendMoneyFeeAtom,
    AppIcon,
    IconInformation,
    AppButton,
  },
  props: {
    ...props,
    title,
    showInfo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style scoped>
.fee {
  @apply float-left;
}
.appIcon {
  @apply align-middle;
  @apply h-4;
}

.button {
  @apply w-4 ml-1;
  display: flex;
}

::v-deep .button.button--icon .button-inner {
  @apply h-4;
  @apply w-4;
}
.fee-title {
  display: flex;
  align-items: center;
}
</style>
