<template>
  <div :class="dotColor">
    <div class="rate-dot-inner"></div>
  </div>
</template>


<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'XeDot',
  props: {
    rateCss: String,
  },
  setup(props) {
    const dotColor = computed(() => {
      return 'rate-dot rate-dot-' + props.rateCss
    })

    return {
      dotColor
    }
  },
}
</script>

<style scoped>
.rate-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: background-color 200ms linear;
  @apply mx-1;

  .rate-dot-inner {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: background-color 200ms linear;
  }
}

.rate-dot-green {
  background-color:rgba(0, 128, 77, 0.2);
  .rate-dot-inner {
    background-color:rgba(0, 128, 77,1);
  }
}
.rate-dot-orange {
 background-color: rgba(255,216,0,0.2);
  .rate-dot-inner {
    background-color: rgba(255,216,0,1);
  }
}
.rate-dot-red {
  background-color: rgba(255, 51, 102, 0.2);
  .rate-dot-inner {
    background-color: rgba(255, 51, 102, 1);
  }
}

</style>