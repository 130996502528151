<template>
  <div>
    <AppSpinnerBig :loading="loading"></AppSpinnerBig>
    <div v-if="isOpenPaymentCountry">
      <AppLink v-if="showLink" @click="openLocation">{{
        $t('ComponentCashPickupLocation.SeeLocationsText').value
      }}</AppLink>
      <div v-else>
        {{ $t('ComponentCashPickupLocation.TextGoToAnyLocation').value }} {{ countryName }}<br />
        <AppButton class="location-button" @click="openLocation">
          {{ $t('ComponentCashPickupLocation.SeeLocationsText').value }}
          <AppIcon name=" ">
            <IconChevronRight />
          </AppIcon>
        </AppButton>
      </div>
    </div>
    <div v-if="!isOpenPaymentCountry && agent && agent.openPayment">
      <AppLink v-if="showLink" class="delivery-agent" @click="openLocation"
        >{{ $t('ComponentCashPickupLocation.TextAny').value }} {{ agent.name }}
        {{ $t('ComponentCashPickupLocation.TextLocations').value }}</AppLink
      >
      <div v-else>
        {{ $t('ComponentCashPickupLocation.TextGoToAny').value }} {{ agent.name }}<br />
        <AppButton class="location-button" @click="openLocation">
          {{ $t('ComponentCashPickupLocation.SeeLocationsText').value }}
          <AppIcon name=" ">
            <IconChevronRight />
          </AppIcon>
        </AppButton>
      </div>
    </div>
    <div v-if="!isOpenPaymentCountry && agent && !agent.openPayment">
      <AppLink v-if="showLink" class="delivery-agent" @click="openLocation">{{
        agent.name
      }}</AppLink>
      <div v-else>
        {{ $t('ComponentCashPickupLocation.TextGoTo').value }} {{ agent.name }}<br />
        <AppButton class="location-button" @click="openLocation">
          {{ $t('ComponentCashPickupLocation.SeeLocationText').value }}
          <AppIcon name=" ">
            <IconChevronRight />
          </AppIcon>
        </AppButton>
      </div>
    </div>
    <AgentDetailsDialog
      v-if="showAgentDetailsModal"
      v-model="showAgentDetailsModal"
      :agent-details="agentLocation"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { IconChevronRight } from '@oen.web.vue2/icons'
import AgentDetailsDialog from '@galileo/components/Views/Delivery/AgentDetailsDialog'

import { AppSpinnerBig, AppLink, AppButton, AppIcon } from '@oen.web.vue2/ui'

import { useRouter } from '@galileo/composables/useRouter'

import {
  useI18nStore,
  useSendMoneyStore,
  useCountriesStore,
  useAppStore,
  useTransactionStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyCashPickupLocation',
  components: {
    AppSpinnerBig,
    AppLink,
    AppButton,
    AppIcon,
    IconChevronRight,
    AgentDetailsDialog,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    cityName: {
      type: String,
      required: true,
    },
    stateName: {
      type: String,
      required: true,
    },
    agent: {
      type: Object,
      required: false,
    },
    agentLocation: {
      type: Object,
      required: false,
    },
    showLink: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const router = useRouter()

    const { getLocationUrl } = useTransactionStore()

    let showAgentDetailsModal = ref(false)
    const loading = ref(false)

    let isOpenPaymentCountry = false
    if (!props.agent) {
      isOpenPaymentCountry = true
    }
    const recipientDeliveryMethod = sendMoneyStore.form.deliveryMethod

    const countryName = computed(() => {
      const country = countriesStore.getCountryByIsoCode(props.country)
      if (country) {
        return country.text
      }
      return props.country
    })

    const openLocation = async () => {
      const url = await getLocationUrl({
        country: props.country,
        currency: props.currency,
        cityName: props.cityName,
        stateName: props.stateName,
        agent: props.agent,
        agentLocation: props.agentLocation,
        loadingRef: loading,
      })
      appStore.openWindow(url)
    }

    return {
      isOpenPaymentCountry,
      recipientDeliveryMethod,
      showAgentDetailsModal,
      openLocation,
      countryName,
      loading,
      $t,
    }
  },
}
</script>
<style scoped>
.location-button {
  @apply mt-4;
  width: auto;
  @apply bg-blue-lighter text-blue-text !important;

  ::v-deep .button-inner {
    padding: 8px 16px !important;
    font-size: 14px;
  }
}
</style>
