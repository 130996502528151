<template>
  <SendMoneyFeeListItem
    v-if="shouldShowFeeDiscount && !promoBonus"
    :title="title"
    :loading="loading"
    :fee="feeDiscount"
    :currency="currencyFrom"
    class="promo"
  />
</template>

<script>
import { computed } from '@vue/composition-api'
import { SendMoneyFeeListItem } from '@galileo/components/Views/SendMoney/Molecules'
import { title, loading } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import { useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyPromoDiscount',
  emits: [],
  components: {
    SendMoneyFeeListItem,
  },
  props: {
    title,
    loading,
  },
  setup() {
    const sendMoneyStore = useSendMoneyStore()

    const feeDiscount = computed(() => sendMoneyStore.getFeeDiscount(true))
    const shouldShowFeeDiscount = computed(() => feeDiscount.value && feeDiscount.value <= 0)
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)

    const promoBonus = computed(() => sendMoneyStore.getPromoBonusAmount(true))

    return {
      shouldShowFeeDiscount,
      feeDiscount,
      currencyFrom,
      promoBonus,
    }
  },
}
</script>

<style scoped>
.promo {
  @apply text-green-text;
  @apply type-subtitle;
  @apply font-bold;

  ::v-deep .description-list-item-definition {
    @apply text-green-text font-bold;
  }
}
</style>
