<template>
  <div class="recipient-gets-explanation">
    <div
      v-if="countryTo === 'LB' && deliveryMethod === 'CashPayout' && currencyTo === 'USD'"
      class="terms"
    >
      <p class="terms-heading">
        {{ $t('PageSendMoneySummary.LebanonTransferText').value }}
      </p>
    </div>
    <div v-else-if="customerRegion === 'US'" class="terms">
      <p class="terms-heading">
        {{ $t('PageSendMoneySummary.BanksText').value }}
      </p>
    </div>

    <div class="terms">
      <div
        class="terms-heading"
        v-html="
          $t('PageSendMoneySummary.TermsAndConditionsText', { termsLink, privacyPolicyLink }).value
        "
      />
    </div>
    <div
      v-if="paymentMethod === 'DirectDebit' && (customerRegion === 'CA' || customerRegion === 'US')"
      class="terms"
    >
      <div
        class="terms-heading"
        v-html="$t('PageSendMoneySummary.PreAuthAgreementtext', { preAuthAgreementLink }).value"
      />
    </div>
    <div v-if="customerRegion === 'AU'" class="terms">
      <div
        class="terms-heading"
        v-html="
          $t('ComponentSendMoneySummaryDisclaimer.DisclosureStatementsTextAU', {
            productDisclosureLink,
            financialServicesGuideLink,
          }).value
        "
      />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { useI18nStore, useSendMoneyStore, useResourcesStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'SendMoneySummaryDisclaimer',
  props: {},
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const resourcesStore = useResourcesStore()
    const sendMoneyStore = useSendMoneyStore()

    let customerRegion = authStore.userProfile.customer.region
    const customerCountry = authStore.userProfile.country
    const countryTo = sendMoneyStore.getCountryTo
    const currencyTo = sendMoneyStore.form.currencyTo
    const paymentMethod = sendMoneyStore.form.paymentMethod
    const deliveryMethod = sendMoneyStore.form.deliveryMethod

    const termsLink = computed(() => {
      return resourcesStore.getTermsAndConditionsUrl
    })
    const privacyPolicyLink = computed(() => {
      return resourcesStore.getPrivacyPolicyUrl
    })
    const productDisclosureLink = computed(() => {
      return resourcesStore.getProductDisclosureStatementUrl
    })
    const financialServicesGuideLink = computed(() => {
      return resourcesStore.getFinancialServicesGuideUrl
    })
    const preAuthAgreementLink = computed(() => {
      return resourcesStore.getPreAuthorizedDebitAgreement
    })

    return {
      $t,
      customerRegion,
      paymentMethod,
      deliveryMethod,
      customerCountry,
      countryTo,
      currencyTo,
      termsLink,
      privacyPolicyLink,
      productDisclosureLink,
      financialServicesGuideLink,
      preAuthAgreementLink,
    }
  },
}
</script>

<style scoped>
::v-deep .link {
  @apply font-medium;
  font-size: 14px;
}
.recipient-gets-explanation {
  @apply mb-6 text-secondary-text;
  font-size: 13px;
}
.terms {
  font-size: 13.5px;
  @apply mb-4 text-secondary-text;
  .terms-heading {
    @apply mb-2;
    ::v-deep a {
      color: rgba(0, 108, 224, 1);
    }
  }
}
</style>
