<template>
  <AppDescriptionListItem>
    <template #title>
      {{ title }}
      <!-- <AppButton v-if="showInfo" theme="icon" @click="$emit('infoDialog', $event)">
        <AppIcon name=" " class="appIcon">
          <IconInformation />
        </AppIcon>
      </AppButton> -->
      <XeTooltip v-if="showInfo" class="text-secondary-text" activation="click" side="bottomInfo">
        <template #wrappedElement>
          <AppIcon name=" " class="appIcon">
            <IconInformation />
          </AppIcon>
        </template>
        <template #tooltipContent>
          <div>
            {{ $t('PageSendMoneyAmount.FeeInfoText').value }}
          </div>
        </template>
      </XeTooltip>
    </template>
    <template #content>
      <Transition name="fade" mode="out-in">
        <slot name="content">
          <div class="moneyRate">
            <XeDot class="xe-dot" :rate-css="rateIndicator" />
            <SendMoneyRateAtom
              key="value"
              :tag="tag"
              :loading="loading"
              :rate-to="rateTo"
              :currency-to="currencyTo"
              :rate-from="rateFrom"
              :currency-from="currencyFrom"
              :pre-text="preText"
            />
          </div>
        </slot>
      </Transition>
    </template>
  </AppDescriptionListItem>
</template>

<script>
import { computed } from '@vue/composition-api'
import { title } from '@galileo/components/Views/SendMoney/Atoms/-common-props'
import SendMoneyRateAtom, {
  props,
} from '@galileo/components/Views/SendMoney/Atoms/SendMoneyRateAtom'
import XeDot from '@galileo/components/XeDot/XeDot'
import { IconInformation } from '@oen.web.vue2/icons'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'

import { AppDescriptionListItem, AppIcon } from '@oen.web.vue2/ui'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyRateListItem',
  emits: ['infoDialog'],
  components: {
    AppDescriptionListItem,
    SendMoneyRateAtom,
    AppIcon,
    XeDot,
    IconInformation,
    XeTooltip,
  },
  props: {
    ...props,
    title,
    showInfo: {
      type: Boolean,
      default: true,
    },
    preText: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    let lastRate = computed(() => sendMoneyStore.getLastRate(true))
    let lastUpdate = null
    let lastColor = 'orange'
    const rateIndicator = computed(() => {
      if (!props.lastUpdate) {
        lastColor = 'orange'
      } else if (lastUpdate !== props.lastUpdate && props.rateTo) {
        if (!lastRate.value) {
          sendMoneyStore.lastRate = props.rateTo
        }
        if (lastRate.value < props.rateTo) {
          // rate improved
          lastColor = 'green'
        } else if (lastRate.value > props.rateTo) {
          // rate reduced
          lastColor = 'red'
        } else {
          lastColor = 'orange' //rate unchanged
        }
        sendMoneyStore.lastRate = props.rateTo
        lastUpdate = props.lastUpdate
      }
      return lastColor
    })

    return {
      rateIndicator,
      $t,
    }
  },
}
</script>

<style scoped>
.moneyRate {
  @apply flex items-center;
}
.appIcon {
  @apply align-middle;
  @apply h-4;
}
.button {
  width: 0%;
}

::v-deep .button.button--icon .button-inner {
  @apply h-4;
  @apply w-4;
}
</style>
